import React, { useState, useRef, useEffect } from 'react';
import { string, oneOf, shape, number, bool } from 'prop-types';

import classnames from 'classnames';
import { escape } from 'html-escaper';
import { Style } from 'nordic/style';
import get from 'lodash/get';
import { Head } from 'nordic/head';
import { Modal } from '@andes/modal';
import Close32 from '@andes/icons/Close32';
import { TextField } from '@andes/textfield';
import { Switch } from '@andes/switch';
import { Dropdown, DropdownItem } from '@andes/dropdown';
import { Button, ButtonText, FloatingActionButton } from '@andes/button';
import { Snackbar } from '@andes/snackbar';

import SearchBookmarkCreatedIconMobile from '../../components-v2/icons/sources/search-bookmark-created.mobile.delete';
import APIService from '../../../services/alert';
import { trackEvent } from './trackings/track';
import SaveSearchButton from './components/save-search-button.mobile';
import useTimesViewed from '../../hooks/use-times-viewed';
import { useUser } from '../context/user';

const SEARCH_TYPE_NEW = 'new_search_configuration';
const SEARCH_TYPE_CREATED = 'created_search_configuration';
const SEARCH_TYPE_MODAL = 'modal_save_configuration';

const FAB_EXPANDED = 'expanded';
const FAB_COLLAPSED = 'collapsed';

const namespace = 'ui-search-alert';

const OFFSET_X = -2;
const OFFSET_Y = -70;

const redirect = (loginUrl) => {
  window.location.href = loginUrl;
};

const renderSaveNewSearchGuest = (props) => {
  const { loginUrl, infoTooltipTimesViewed, isTooltipOpen, setOpenTooltip, behavior } = props;

  const { isAvailable, handleClose } = infoTooltipTimesViewed;

  const handleRedirect = () => {
    if (isAvailable && isTooltipOpen) {
      setOpenTooltip(false);
      handleClose();
    }

    redirect(loginUrl);

    return false;
  };

  return (
    <span className={`${namespace}__save-info-tooltip-container`}>
      <Style renderChildrenInPlace={false}>
        {
          'html, body {overscroll-behavior: none;} .nav-header-menu-mobile-logged,.nav-header-menu-mobile-guest {z-index: 100;}'
        }
      </Style>
      <SaveSearchButton
        tooltipClassname={`${namespace}__save-info-tooltip`}
        buttonClassname={classnames(`${namespace}__fab`, `${namespace}__${behavior}`)}
        offsetY={OFFSET_Y}
        offsetX={OFFSET_X}
        onClick={handleRedirect}
        {...props}
      />
    </span>
  );
};

const renderSearchCreated = (props) => {
  const behavior = get(props, 'behavior');
  const isSnackbarSuccess = get(props, 'isSnackbarSuccess');
  const snackbarSuccess = get(props, 'snackbar_success', null);

  const trackAdminRedirect = get(props, 'action.tracks', null);

  const buttonText = get(props, 'action.label.text', null);
  const buttonTarget = get(props, 'action.label.target', null);

  const handleRedirect = (targetUrl) => {
    if (trackAdminRedirect) {
      const { melidata_track } = trackAdminRedirect;

      trackEvent(null, melidata_track);
    }

    redirect(targetUrl);
  };

  return (
    <>
      <Style renderChildrenInPlace={false}>
        {
          'html, body {overscroll-behavior: none;} .nav-header-menu-mobile-logged,.nav-header-menu-mobile-guest {z-index: 100;}'
        }
      </Style>
      {isSnackbarSuccess && (
        <Snackbar
          message={snackbarSuccess.label.text}
          color="green"
          show={isSnackbarSuccess}
          delay={6000}
          action={{
            text: snackbarSuccess.action.label.text,
            onClick: () => handleRedirect(snackbarSuccess.action.target),
            position: 'bottom',
          }}
        />
      )}
      <span className={`${namespace}__save-info-tooltip-container`}>
        <FloatingActionButton
          className={classnames(`${namespace}__fab`, `${namespace}__${behavior}`)}
          behavior={behavior}
          text={buttonText}
          srLabel={buttonText}
          hierarchy="loud"
          size="large"
          onClick={() => handleRedirect(buttonTarget)}
          icon={<SearchBookmarkCreatedIconMobile />}
        />
      </span>
    </>
  );
};

const renderSaveNewSearch = (props) => {
  const {
    behavior,
    saveNewSearchProps,
    loginUrl,
    isModalOpen,
    setOpenModal,
    searchModalProps,
    searchTitle,
    setSearchTitle,
    isNotificationsEnabled,
    setEnableNotifications,
    frequencyValue,
    setFrequencyValue,
    expirationValue,
    setExpirationValue,
    isDisabled,
    isSubmitLoading,
    setSubmitLoading,
    isSnackbarError,
    setSnackbarSuccess,
    setSnackbarError,
    setComponentVisible,
    searchCreated,
    infoTooltipTimesViewed,
    isTooltipOpen,
    setOpenTooltip,
    webview,
    subscriptionId,
  } = props;

  const trackSaveIntention = get(saveNewSearchProps, 'title_configuration.tracks', null);

  const modalTitle = get(searchModalProps, 'title_configuration.title', null);
  const modalSubTitle = get(searchModalProps, 'title_configuration.subtitle', null);
  const inputLabel = get(searchModalProps, 'title_configuration.content.label', null);
  const titleMaxlength = get(searchModalProps, 'title_configuration.content.title_max_length', null);
  const titlePlaceholder = get(searchModalProps, 'title_configuration.content.title_placeholder', null);
  const titleMaxLengthPlaceholder = get(searchModalProps, 'title_configuration.content.title_length_placeholder', null);

  const notificationsTitle = get(searchModalProps, 'notifications_configuration.content.title', null);
  const enableNotificationsText = get(searchModalProps, 'notifications_configuration.content.action.text', null);
  const frequencyLabel = get(searchModalProps, 'notifications_configuration.content.frequency.label', null);
  const frequencyPlacehodler = get(searchModalProps, 'notifications_configuration.content.frequency.placeholder', null);
  const frequencyOptions = get(searchModalProps, 'notifications_configuration.content.frequency.options', null);
  const expirationLabel = get(searchModalProps, 'notifications_configuration.content.expiration.label', null);
  const expirationPlacehodler = get(
    searchModalProps,
    'notifications_configuration.content.expiration.placeholder',
    null,
  );
  const expirationOptions = get(searchModalProps, 'notifications_configuration.content.expiration.options', null);

  const submitButtonText = get(searchModalProps, 'action_configuration.label.text', null);
  const trackSaveAction = get(searchModalProps, 'action_configuration.tracks', null);
  const alertCreationData = get(searchModalProps, 'action_configuration.alert_creation_data', null);

  const trackCancelIntention = get(searchModalProps, 'tracks', null);
  const snackbarError = get(searchModalProps, 'snackbar_error', null);
  const trackSaveCongrats = get(searchModalProps, 'snackbar_success.tracks', null);

  const { isAvailable, handleClose } = infoTooltipTimesViewed;

  const cleanInput = (value) => {
    if (typeof value === 'string') {
      return value.replace(/\s+/g, ' ').trimStart();
    }

    return value;
  };

  const handleButton = () => {
    if (isAvailable && isTooltipOpen) {
      setOpenTooltip(false);
      handleClose();
    }

    if (trackSaveIntention) {
      const { melidata_track } = trackSaveIntention;

      trackEvent(null, melidata_track);
    }

    setOpenModal(true);

    return false;
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSearchTitle('');
    setEnableNotifications(false);
    setFrequencyValue('');
    setExpirationValue(0);

    if (trackCancelIntention) {
      const { melidata_track } = trackCancelIntention;

      trackEvent(null, melidata_track);
    }
  };

  const handleSearchTitle = (e) => setSearchTitle(cleanInput(e.target.value));

  const handleEnableNotifications = (e) => {
    const isChecked = e.target.checked;

    setEnableNotifications(isChecked);

    if (!isChecked) {
      setFrequencyValue('');
      setExpirationValue(0);
    }
  };

  const handleFrequency = (e) => setFrequencyValue(e.target.value);

  const handleExpiration = (e) => setExpirationValue(e.target.value);

  const handleSuccess = () => {
    setComponentVisible(searchCreated.type);
    setSubmitLoading(false);
    setSnackbarSuccess(true);

    if (!webview && trackSaveCongrats) {
      const { melidata_track } = trackSaveCongrats;

      trackEvent(null, melidata_track);
    }

    if (webview) {
      /* eslint-disable no-undef */
      MobileWebKit.executeNative({
        method: 'SAVE_SEARCH_SUCCESS',
        args: {
          status: 'SUCCESS',
        },
      });
      MobileWebKit.navigation.pop();
      /* eslint-enable no-undef */
    }
  };

  const handleError = (ex) => {
    if (ex?.response && ex?.response.status === 403) {
      redirect(loginUrl);
    }

    setSubmitLoading(false);
    setSnackbarError(true);

    if (webview) {
      /* eslint-disable no-undef */
      MobileWebKit.executeNative({
        method: 'SAVE_SEARCH_ERROR',
        args: {
          status: 'ERROR',
        },
      });
      MobileWebKit.navigation.pop();
      /* eslint-enable no-undef */
    }
  };

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    escape(searchTitle);

    setSubmitLoading(true);

    if (trackSaveAction) {
      const melidata_track = {
        ...trackSaveAction.melidata_track,
        event_data: {
          ...trackSaveAction.melidata_track.event_data,
          title: searchTitle,
          frequency: isNotificationsEnabled ? frequencyValue : null,
          expiration: isNotificationsEnabled ? parseInt(expirationValue, 10) : null,
        },
      };

      trackEvent(null, melidata_track);
    }

    if (webview) {
      /* eslint-disable no-undef */
      MobileWebKit.lifecycle.unsubscribe(subscriptionId);
      /* eslint-enable no-undef */
    }

    const alertCreationPayload = {
      ...alertCreationData,
      title: searchTitle === '' ? alertCreationData?.title : searchTitle,
      enable_notifications: isNotificationsEnabled,
      frequency: isNotificationsEnabled ? frequencyValue : null,
      ttl: isNotificationsEnabled ? expirationValue : null,
      status: isNotificationsEnabled ? 'active' : 'inactive',
    };

    APIService.addAlert(alertCreationPayload).then(handleSuccess).catch(handleError);
  };

  const modalContent = (
    <div className={`${namespace}__modal-content`}>
      <TextField
        label={<div className={`${namespace}__modal-input-label`}>{inputLabel}</div>}
        helper={titleMaxLengthPlaceholder}
        placeholder={titlePlaceholder}
        maxLength={titleMaxlength}
        value={searchTitle}
        onChange={handleSearchTitle}
        countdown
      />
      <hr />
      <p>{notificationsTitle}</p>
      <Switch
        label={enableNotificationsText}
        className={`${namespace}__notifications-switch`}
        labelPosition="right"
        checked={isNotificationsEnabled}
        onChange={handleEnableNotifications}
      />
      <div className={`${namespace}__frequency-container`}>
        <Dropdown
          className={`${namespace}__frequency-dropdown`}
          type="form"
          label={frequencyLabel}
          placeholder={frequencyPlacehodler}
          value={frequencyValue}
          onChange={handleFrequency}
          disabled={!isNotificationsEnabled}
          menuAlignment="bottom"
        >
          {frequencyOptions.map(({ value, text: frequencyText }) => (
            <DropdownItem key={value} value={value} title={frequencyText} />
          ))}
        </Dropdown>
        <Dropdown
          className={`${namespace}__expiration-dropdown`}
          type="form"
          label={expirationLabel}
          placeholder={expirationPlacehodler}
          value={expirationValue}
          onChange={handleExpiration}
          disabled={!isNotificationsEnabled}
          menuAlignment="bottom"
        >
          {expirationOptions.map(({ value, text: expirationText }) => (
            <DropdownItem key={value} value={value} title={expirationText} />
          ))}
        </Dropdown>
      </div>
    </div>
  );

  const actionButton = (
    <div className={`${namespace}__submit-container`}>
      <Button disabled={isDisabled} fullWidth onClick={handleSubmit} loading={isSubmitLoading}>
        <ButtonText>{submitButtonText}</ButtonText>
      </Button>
    </div>
  );

  const webViewView = (
    <div className={`${namespace}__modal-container`}>
      <Head>
        <title>{modalTitle}</title>
      </Head>
      <div className={`${namespace}__modal-header`}>
        <span>{modalSubTitle}</span>
      </div>
      {modalContent}
      {actionButton}
    </div>
  );

  const webmobileView = (
    <>
      <Style renderChildrenInPlace={false}>
        {
          'html, body {overscroll-behavior: none;} .nav-header-menu-mobile-logged,.nav-header-menu-mobile-guest {z-index: 100;}'
        }
      </Style>
      <span className={`${namespace}__save-info-tooltip-container`}>
        <SaveSearchButton
          tooltipClassname={`${namespace}__save-info-tooltip`}
          buttonClassname={classnames(`${namespace}__fab`, `${namespace}__${behavior}`)}
          offsetY={OFFSET_Y}
          offsetX={OFFSET_X}
          onClick={handleButton}
          {...props}
        />
      </span>
      <Modal
        type="full"
        open={isModalOpen}
        onClose={handleCloseModal}
        closeButtonSrLabel="Close modal"
        closable
        className={`${namespace}__save-modal`}
        title=""
        actions={{
          fixed: false,
          primary: actionButton,
        }}
      >
        <div className={`${namespace}__modal-container`}>
          <div className={`${namespace}__modal-close-icon`}>
            <Button
              hierarchy="transparent"
              size="large"
              aria-label="Close"
              className="button-close"
              onClick={() => setOpenModal(false)}
            >
              <Close32 />
            </Button>
          </div>
          <div className={`${namespace}__modal-header`}>
            <h1>{modalTitle}</h1>
            <span>{modalSubTitle}</span>
          </div>
          {modalContent}
        </div>
      </Modal>
      <Snackbar message={snackbarError.label.text} color="red" show={isSnackbarError} delay={6000} />
    </>
  );

  return webview ? webViewView : webmobileView;
};

const SearchSave = (props) => {
  const [componentVisible, setComponentVisible] = useState(props.component_visible);
  const { loggedIn, loginUrl } = useUser();
  const [isTooltipOpen, setIsTooltipOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [behavior, setBehavior] = useState(FAB_EXPANDED);
  const [searchTitle, setSearchTitle] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [frequencyValue, setFrequencyValue] = useState('');
  const [expirationValue, setExpirationValue] = useState('');
  const [isNotificationsEnabled, setIsNotificationsEnabled] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSnackbarSuccess, setIsSnackbarSuccess] = useState(false);
  const [isSnackbarError, setIsSnackbarError] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState('');

  const saveNewSearchProps = props?.components?.find((item) => item.type === SEARCH_TYPE_NEW);
  const searchModalProps = props?.components?.find((item) => item.type === SEARCH_TYPE_MODAL);
  const searchCreated = props?.components?.find((item) => item.type === SEARCH_TYPE_CREATED);
  const webview = props.webView;
  const infoTooltip = saveNewSearchProps?.title_configuration?.tooltip || null;

  const infoTooltipTimesViewed = useTimesViewed('infoTooltip', infoTooltip?.max_print);

  const prevScrollY = useRef(0);

  const classNames = classnames({
    [`${namespace}__label-new`]: componentVisible === SEARCH_TYPE_NEW,
  });

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (prevScrollY.current < currentScrollY) {
      setBehavior(FAB_COLLAPSED);
    }

    if (prevScrollY.current > currentScrollY) {
      setBehavior(FAB_EXPANDED);
    }

    prevScrollY.current = currentScrollY;
    infoTooltipTimesViewed.handleClose();
    setIsTooltipOpen(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const isFrequencyAndExpirationEmpty = frequencyValue !== '' && expirationValue > 0;

  useEffect(() => {
    if (searchTitle.trim()) {
      if (isNotificationsEnabled) {
        setIsDisabled(!isFrequencyAndExpirationEmpty);
      } else {
        setFrequencyValue('');
        setExpirationValue(0);
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
  }, [searchTitle, isNotificationsEnabled, isFrequencyAndExpirationEmpty]);

  const { melidata_track } = get(searchModalProps, 'tracks', {});

  useEffect(() => {
    if (webview) {
      /* eslint-disable no-undef */
      MobileWebKit.topbar.setup({
        bar_color: 'FFE600',
      });
      /* eslint-enable no-undef */
    }

    if (webview && melidata_track) {
      setSubscriptionId(
        /* eslint-disable no-undef */
        MobileWebKit.lifecycle.onViewDisappeared(() => {
          trackEvent(null, melidata_track);
        }),
        /* eslint-enable no-undef */
      );
    }
  }, [setSubscriptionId, melidata_track, webview]);

  const newSearchProps = {
    loggedIn,
    loginUrl,
    saveNewSearchProps,
    infoTooltipTimesViewed,
    isTooltipOpen,
    setOpenTooltip: setIsTooltipOpen,
    classNames,
    isModalOpen,
    setOpenModal: setIsModalOpen,
    searchModalProps,
    infoTooltip,
    behavior,
    isDisabled,
    searchTitle,
    setSearchTitle,
    isNotificationsEnabled,
    setEnableNotifications: setIsNotificationsEnabled,
    frequencyValue,
    setFrequencyValue,
    expirationValue,
    setExpirationValue,
    isSubmitLoading,
    setSubmitLoading: setIsSubmitLoading,
    isSnackbarSuccess,
    isSnackbarError,
    setSnackbarSuccess: setIsSnackbarSuccess,
    setSnackbarError: setIsSnackbarError,
    setComponentVisible,
    searchCreated,
    webview,
    subscriptionId,
  };

  if (webview) {
    return renderSaveNewSearch(newSearchProps);
  }

  if (!loggedIn) {
    return renderSaveNewSearchGuest(newSearchProps);
  }

  const snackbar_success = searchModalProps?.snackbar_success;

  const searchCreatedProps = {
    ...searchCreated,
    behavior,
    isSnackbarSuccess,
    snackbar_success,
  };

  switch (componentVisible) {
    case SEARCH_TYPE_NEW:
      return renderSaveNewSearch(newSearchProps);
    case SEARCH_TYPE_CREATED:
      return renderSearchCreated(searchCreatedProps);

    default:
      return null;
  }
};

SearchSave.propTypes = {
  component_visible: string.isRequired,
  components: oneOf([
    shape({
      type: string.isRequired,
      title_configuration: shape({
        label: shape({
          text: string.isRequired,
          text_color: string,
          size: string,
        }),
        tooltip: shape({
          max_print: number.isRequired,
          text: string.isRequired,
          type: string.isRequired,
          location: string.isRequired,
        }),
        icon: shape({
          id: string,
          size: string,
        }),
        tracks: shape({
          melidata_track: shape({
            path: string.isRequired,
          }),
        }),
      }),
    }),
    shape({
      type: string.isRequired,
      title_configuration: shape({
        title: string.isRequired,
        subtitle: string.isRequired,
        content: shape({
          label: string.isRequired,
          title_placeholder: string.isRequired,
          title_length_placeholder: string.isRequired,
          title_max_length: number.isRequired,
        }),
      }),
      notifications_configuration: shape({
        content: shape({
          title: string.isRequired,
          action: shape({
            type: string.isRequired,
            text: string.isRequired,
          }),
          frequency: shape({
            label: string.isRequired,
            placeholder: string.isRequired,
            options: oneOf([
              shape({
                key: number.isRequired,
                value: string.isRequired,
                text: string.isRequired,
              }),
            ]),
          }),
          expiration: shape({
            label: string.isRequired,
            placeholder: string.isRequired,
            options: oneOf([
              shape({
                key: number.isRequired,
                value: string.isRequired,
                text: string.isRequired,
              }),
            ]),
          }),
        }),
      }),
      action_configuration: shape({
        type: string,
        size: string,
        hierarchy: string,
        label: shape({
          text: string.isRequired,
        }),
        target: string,
        alert_creation_data: shape({
          site_id: string.isRequired,
          user_id: number.isRequired,
          platform: string.isRequired,
          enable_notifications: bool.isRequired,
          frequency: string.isRequired,
          ttl: number.isRequired,
          search_params: shape({
            q: string.isRequired,
            category: string.isRequired,
          }),
          title: string.isRequired,
          subtitle: string.isRequired,
          email_body_subtitle: string.isRequired,
        }),
        tracks: shape({
          melidata_track: shape({
            path: string.isRequired,
          }),
        }),
      }),
      snackbar_error: shape({
        label: shape({
          text: string.isRequired,
        }),
      }),
      snackbar_success: shape({
        label: shape({
          text: string.isRequired,
        }),
        tracks: shape({
          melidata_track: shape({
            path: string.isRequired,
          }),
        }),
        action: shape({
          label: shape({
            text: string.isRequired,
          }),
          text: string.isRequired,
        }),
      }),
      tracks: shape({
        melidata_track: shape({
          path: string.isRequired,
        }),
      }),
    }),
    shape({
      type: string.isRequired,
      background_color: string,
      corner_radius: string,
      action: shape({
        label: shape({
          text: string.isRequired,
          text_color: string,
          size: string,
          target: string.isRequired,
          icon: shape({
            id: string,
            size: string,
          }),
        }),
        tracks: shape({
          melidata_track: shape({
            path: string.isRequired,
          }),
        }),
      }),
    }),
  ]),
};

export default SearchSave;
