import React from 'react';
import { string } from 'prop-types';

import { BuildIcon } from '../utils';

const ICON_ID = 'ic_search_bookmark_mobile';

const SearchBookmarkIconMobile = (props) => (
  <BuildIcon {...props} viewBox="0 0 12 16" iconId={ICON_ID} width="12" height="16" />
);

SearchBookmarkIconMobile.propTypes = {
  className: string,
  style: string,
};

export default SearchBookmarkIconMobile;
