import React from 'react';
import { bool, func, number, shape, string } from 'prop-types';

import get from 'lodash/get';
import { Tooltip } from '@andes/tooltip';
import { FloatingActionButton } from '@andes/button';

import SearchBookmarkIconMobile from '../../../components-v2/icons/sources/search-bookmark.mobile.delete';

const SaveSearchButton = ({
  saveNewSearchProps,
  infoTooltipTimesViewed,
  isTooltipOpen,
  infoTooltip,
  tooltipClassname,
  buttonClassname,
  onClick,
  offsetY,
  offsetX,
  behavior,
}) => {
  const { handleClose } = infoTooltipTimesViewed;

  const buttonTitle = get(saveNewSearchProps, 'title_configuration.label.text', null);
  const tooltipText = get(saveNewSearchProps, 'title_configuration.tooltip.text', null);
  const tooltipType = get(saveNewSearchProps, 'title_configuration.tooltip.type', null);

  const { isAvailable } = infoTooltipTimesViewed;

  if (infoTooltip && isAvailable && isTooltipOpen) {
    return (
      <Tooltip
        content={tooltipText}
        side="top"
        type={tooltipType}
        trigger="no-trigger"
        visible
        closable
        onClose={handleClose}
        onClickOutside={handleClose}
        closeIconLabel="Close"
        className={tooltipClassname}
        offsetY={offsetY}
        offsetX={offsetX}
      >
        <FloatingActionButton
          className={buttonClassname}
          behavior={behavior}
          text={buttonTitle}
          srLabel={buttonTitle}
          hierarchy="loud"
          size="large"
          onClick={onClick}
          icon={<SearchBookmarkIconMobile color="white" />}
        />
      </Tooltip>
    );
  }

  return (
    <FloatingActionButton
      className={buttonClassname}
      behavior={behavior}
      text={buttonTitle}
      srLabel={buttonTitle}
      hierarchy="loud"
      size="large"
      onClick={onClick}
      icon={<SearchBookmarkIconMobile color="white" />}
    />
  );
};

SaveSearchButton.propTypes = {
  behavior: string,
  buttonClassname: string,
  infoTooltip: shape,
  infoTooltipTimesViewed: shape({
    isAvailable: bool,
    handleClose: func,
  }),
  isTooltipOpen: bool,
  offsetX: number,
  offsetY: number,
  saveNewSearchProps: shape({
    title_configuration: shape({
      label: shape({
        text: string,
      }),
      tooltip: shape({
        text: string,
        type: string,
      }),
    }),
  }),
  tooltipClassname: string,
  onClick: func,
};

export default SaveSearchButton;
