import React, { useState } from 'react';

import Link from '../../components-v2/link';
import { useUser } from '../context/user';
import APIService from '../../../services/alert';
import IconAlertEdit from '../../components-v2/icons/sources/alert-edit';
import IconAlertNew from '../../components-v2/icons/sources/alert-new';
import { trackClickEvent } from '../map/track';

const ALERT_TYPE_NEW = 'new';
const ALERT_TYPE_EDIT = 'edit';

const namespace = 'ui-search-alert';

const redirect = (loginUrl) => {
  window.location.href = loginUrl;
};

const renderAlertEdit = (label, action) => (
  <div className={`${namespace}__card ${namespace}__card--edit`}>
    <span className={`${namespace}__content`}>
      <IconAlertEdit />
      {label.text}
      <Link
        className={`${namespace}__action-link`}
        href={action.target}
        tabIndex="-1"
        isInternal={false}
        title={action?.label?.text}
      >
        {action.label.text}
      </Link>
    </span>
  </div>
);

const renderAlertNew = (
  label,
  action,
  trackEvents,
  alertCreationNext,
  loggedIn,
  loginUrl,
  handleAlertCreationSuccess,
) => {
  const handleAlert = (e) => {
    e.preventDefault();

    if (!loggedIn) {
      redirect(loginUrl);

      return;
    }

    if (trackEvents) {
      const { melidata, analytics } = trackEvents;

      trackClickEvent(analytics, melidata);
    }

    APIService.addAlert(action.alert_creation_data)
      .catch((ex) => {
        if (ex.response && ex.response.status === 403) {
          redirect(loginUrl);
        }
      })
      .then(handleAlertCreationSuccess(alertCreationNext))
      .catch((err) => {
        throw new Error(err);
      });
  };

  return (
    <form
      action="/search/alert"
      className={`${namespace}__card ${namespace}__card--new`}
      method="POST"
      onSubmit={handleAlert}
    >
      <button type="submit" className={`${namespace}__btn`}>
        <IconAlertNew />
        {label.text}
      </button>
      <input type="hidden" name="logginUrl" value={loginUrl} />
      <input
        type="hidden"
        name="alertCreationData"
        value={action?.alert_creation_data ? encodeURIComponent(JSON.stringify(action.alert_creation_data)) : ''}
      />
    </form>
  );
};

const Alert = (props) => {
  const [alertState, setAlertState] = useState(props);
  const { loggedIn, loginUrl } = useUser();

  const { alert_type, label, analytics_track_event, melidata_track_value, action, alert_creation_next } = alertState;

  const trackEvents = {
    analytics: analytics_track_event,
    melidata: melidata_track_value,
  };

  function handleAlertCreationSuccess(alertCreationNext) {
    setAlertState(alertCreationNext);

    const tracks = {
      melidata: {
        melidata_track: {
          path: '/search/alert_congrats',
          event_data: { frequency: 'daily', status: 'active' },
        },
      },
      analytics: {
        analytics_track_category: 'SEARCH_ALERT_CONGRATS',
        analytics_track_action: 'SEARCH_ALERT',
        analytics_track_label: 'PRIMARY',
      },
    };
    const { analytics, melidata } = tracks;

    trackClickEvent(analytics, melidata);
  }

  switch (alert_type) {
    case ALERT_TYPE_EDIT:
      return renderAlertEdit(label, action, trackEvents);
    case ALERT_TYPE_NEW:
      return renderAlertNew(
        label,
        action,
        trackEvents,
        alert_creation_next,
        loggedIn,
        loginUrl,
        handleAlertCreationSuccess,
      );

    default:
      return null;
  }
};

export default Alert;
